import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { FaLongArrowAltDown } from 'react-icons/fa';

import { Container } from '../lib';

const getBackdrop = graphql`
  query {
    hero_image: file(relativePath: { eq: "dad_photo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Hero = ({ className }) => {
  const { hero_image } = useStaticQuery(getBackdrop);

  return (
    <section className={className}>
      <Image
        className='hero_image'
        alt='one dad at a time'
        fluid={hero_image.childImageSharp.fluid}
        draggable='false'
        objectPosition='0% 100%'
      />
      <div className='overlay'>
        <Container className='cta-wrap'>
          <div className='cta'>
            <h1 className='bold'>One Dad at a Time.</h1>
            <a href='/#message'>Let's Chat!</a>
          </div>
        </Container>
      </div>
      <div className='scroll'>
        <p className='bold'>
          <a href='#about'>Learn More</a>
        </p>
        <FaLongArrowAltDown />
      </div>
    </section>
  );
};

const StyledHero = styled(Hero)`
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: var(--blue4);
  margin-top: -4rem;

  .hero_image {
    height: 100%;
    position: relative;
    filter: blur(0.5rem);
  }
  .overlay {
    background-color: rgba(66, 77, 94, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .cta-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;

    .cta {
      padding: 2rem;
      grid-area: cta;
      color: var(--white);
      text-transform: capitalize;
      h1 {
        line-height: var(--font-size-h1);
        padding-bottom: 1.65rem;
        text-shadow: 0 0 5px var(--blue4);
      }
      a {
        text-transform: uppercase;
        margin: 0;
        padding: 0.5rem 2rem;
        background-color: var(--red);
        color: var(--white);
        font-weight: var(--font-weight-extrabold);
        border-radius: var(--font-size-h1);
        transition: all 0.3s cubic-bezier(0.57, 0.12, 0.16, 0.92);

        &:hover,
        &:active {
          background-color: var(--yellow);
          color: var(--blue3);
        }
      }
    }

    @media (min-width: 500px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: '. cta';
      align-items: center;
      text-align: left;
      .cta {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        h1 {
          font-size: calc(var(--font-size-h1) * 1.4);
          line-height: calc(var(--font-size-h1) * 1.4);
        }
      }
    }
  }

  .scroll {
    color: var(--white);

    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--font-size-h4);
    p {
      padding: 0.25rem 1rem;
      font-size: calc(var(--font-size-base) / 1.75);
      border-radius: var(--font-size-base);
      background-color: var(--white);
      color: var(--blue4);
      margin-bottom: -1rem;
      text-transform: lowercase;
      box-shadow: 0 0 5px 1px var(--blue4);
    }
  }

  @media (min-width: 500px) {
    margin-top: -8rem;
  }
`;

export default StyledHero;
