import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import { Container } from '../lib';
import Spike from '../../images/rail_spike.svg';

const getImages = graphql`
  query {
    juvenile_incarceration: file(
      relativePath: { eq: "juvenile_incarceration_rates.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    suicide: file(relativePath: { eq: "minor_suicide_rates.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homelessness: file(relativePath: { eq: "homeless_and_runaway_rates.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    psychiatry: file(relativePath: { eq: "psychiatric_needs.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dropout_rates: file(relativePath: { eq: "dropout_rates.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    unplanned_pregnancy: file(
      relativePath: { eq: "unplanned_pregnancies.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teen_pregnancy: file(relativePath: { eq: "teen_pregnancies.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Callouts = ({ className }) => {
  const {
    juvenile_incarceration,
    suicide,
    homelessness,
    psychiatry,
    dropout_rates,
    teen_pregnancy,
    unplanned_pregnancy,
  } = useStaticQuery(getImages);

  return (
    <div className={className}>
      <Container id='about'>
        <section className='mission-statement'>
          <h1>We encourage, empower, and equip young fathers of unplanned
            pregnancies to become the best dads they can be.</h1>
        </section>
        <Spike className='spike' />
        <section className='first-call'>
          <h1>Are you suddenly a dad?</h1>
          <h1 className='bold'>That's huge.</h1>
          <h1>And your fathering is critically important.</h1>
        </section>
        <Spike className='spike' />
        <section>
          <h1>Fatherlessness is a silent social crisis...</h1>
          <div className='image-section'>
            <div>
              <p>People who grow up fatherless are</p>
              <Image
                alt='about 15 times'
                fluid={juvenile_incarceration.childImageSharp.fluid}
              />
              <p>more likely to be incarcerated as juveniles.</p>
            </div>
            <div>
              <p>People who grow up fatherless are</p>
              <Image alt='5 times' fluid={suicide.childImageSharp.fluid} />
              <p>more likely to commit suicide during their youth.</p>
            </div>
            <div>
              <Image
                alt='80 percent'
                fluid={psychiatry.childImageSharp.fluid}
              />
              <p>
                of adolescents in psychiatric hospitals come from fatherless
                homes.
              </p>
            </div>
            <div>
              <Image
                alt='90 percent'
                fluid={homelessness.childImageSharp.fluid}
              />
              <p>of all homeless and runaway children are fatherless.</p>
            </div>
            <div>
              <p>Fatherless children are</p>
              <Image
                alt='about 7 times'
                fluid={dropout_rates.childImageSharp.fluid}
              />
              <p>more likely to drop out of school.</p>
            </div>
          </div>
        </section>
        <Spike className='spike' />
        <section>
          <h1>...often rooted in unplanned pregnancy.</h1>
          <div className='image-section'>
            <div>
              <Image
                alt='45 percent'
                fluid={unplanned_pregnancy.childImageSharp.fluid}
              />
              <p>of all pregnancies in the United States are unplanned.</p>
            </div>
            <div>
              <Image
                alt='more than 70 percent'
                fluid={teen_pregnancy.childImageSharp.fluid}
              />
              <p>
                of teen & unplanned pregnancies involve at least one parent who
                is fatherless.
              </p>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

const StyledCallouts = styled(Callouts)`
  position: relative;
  background-color: var(--white);
  z-index: 1;
  padding: 5rem 0;

  h1 {
    font-size: var(--font-size-h2);
    line-height: calc(var(--font-size-h2) * 1.25);
    text-align: center;
  }

  .spike {
    display: block;
    width: 20rem;
    min-height: 7.5rem;
    transform: rotateY(180deg);
    height: var(--font-size-h2);
    margin: 5rem auto;
  }
  .first-call {
    text-align: center;

    h1 {
      font-size: var(--font-size-h3);
      line-height: calc(var(--font-size-h3) * 1.25);

      &.bold {
        font-size: var(--font-size-h1);
        line-height: calc(var(--font-size-h2) * 1.75);
      }
    }
  }
  .image-section {
    padding: 5rem 0 0;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    gap: 2.5rem;

    p {
      line-height: calc(var(--font-size-base)-1.25);
    }
    & > div {
      padding: 1rem;
      border: 0.5rem solid var(--blue4);
      box-shadow: var(--shadow);
      width: 100%;
    }

    @media (min-width: 500px) {
      & > div {
        max-width: 45%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    @media (min-width: 800px) {
      & > div {
        max-width: 30%;
      }
    }
  }
  .mission-statement {
    text-transform: uppercase;
  }
`;

export default StyledCallouts;
