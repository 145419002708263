import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Callouts from '../components/Home/Callouts';
import Hero from '../components/Home/Hero';
import ContactForm from '../components/Forms/ContactForm';
import GetInvolved from '../components/Home/GetInvolved';

const IndexPage = () => (
  <Layout>
    <SEO
      title='Home'
      keywords={[`fathering.me`, `fatherlessness`, `new father`, `unplanned`]}
    />
    <Hero />
    <Callouts />
    <ContactForm />
    <GetInvolved />
  </Layout>
);

export default IndexPage;
