import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Container } from '../lib';

const GetInvolved = ({ className }) => (
  <Container className={className} id='get-involved'>
    <h1 className='bold'>Get Involved</h1>
    <ul>
      <li><Link to='/signup'>Get Updates</Link></li>
      <li><Link to='/mentor'>Interested in Mentoring?</Link></li>
      <li><Link to='/sponsor'>Sponsor a Mentor</Link></li>
      <li><Link to='/donate'>Donate</Link></li>
    </ul>
  </Container>
);

const StyledGetInvolved = styled(GetInvolved)`
  text-align: center;
  text-transform: uppercase;
  margin: 2rem auto 5rem;

  ul {
    list-style: none;

    li {
      padding: 0.5rem;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s cubic-bezier(0.57, 0.12, 0.16, 0.92);
      border-radius: var(--font-size-h4);
      border: 2px solid var(--blue4);

      a {
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-extrabold);
        color: var(--blue4);
        transition: all 0.3s cubic-bezier(0.57, 0.12, 0.16, 0.92);
      }

      &:hover,
      &:active {
        background-color: var(--blue4);
        a {
          color: var(--white);
        }
      }
    }
  }

  @media (min-width: 500px) {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.5rem;

      li {
        margin: 0;
      }
    }
  }
  @media (min-width: 800px) {
    ul {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
`;

export default StyledGetInvolved;
