import React from 'react';
import styled from 'styled-components';

import { Container } from '../lib';

const ContactForm = ({ className }) => (
  <div className={className}>
    <Container>
      <div id='message'></div>
      <form
        class='mentor-contact'
        method='post'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        // action='/.netlify/functions/contact-form-handler'
      >
        <header>
          <h1>Want to Learn More?</h1>
          <h2>We'll shoot you a text.</h2>
        </header>

        <input type='hidden' name='bot-field' />
        <input type='hidden' name='form-name' value='contact' />
        <div className='field'>
          <label htmlFor='name'>Name:</label>
          <input type='text' name='name' id='name' />
        </div>
        <div className='field'>
          <label htmlFor='phone'>Phone:</label>
          <input type='text' name='phone' id='phone' />
        </div>
        <div className='field'>
          <label htmlFor='message'>Message:</label>
          <textarea name='message' id='message' rows='6' />
        </div>
        <ul className='actions'>
          <li className='submit'>
            <input type='submit' value='Send Message' />
          </li>
          <li>
            <input type='reset' value='Clear' />
          </li>
        </ul>
      </form>
    </Container>
  </div>
);

const StyledContactForm = styled(ContactForm)`
  padding: 2rem;
  text-align: center;
  background-color: var(--blue4);
  color: var(--white);

  h1 {
    text-transform: uppercase;
    font-size: var(--font-size-h2);
    line-height: calc(var(--font-size-h2) * 1.25);
  }
  h2 {
    padding-top: 1.5rem;
    text-transform: capitalize;
    font-size: var(--font-size-h3);
    line-height: calc(var(--font-size-h3) * 1.25);
  }
  form {
    display: flex;
    flex-flow: column;
    div {
      display: flex;
      flex-direction: column;
      label {
        text-align: left;
        padding: 1rem 1rem 0;
      }
      input,
      textarea {
        border: none;
        border-radius: var(--font-size-base);
        padding: 0 1rem;
        background-color: var(--white);
        color: var(--blue4);
        font-family: var(--font-family-OpenSans);
        font-size: var(--font-size-base);
        line-height: var(--font-size-h3);
        border: 2px solid var(--blue4);

        &:active,
        &:focus {
          border: 2px solid var(--green);
        }
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    padding: 2rem 0 1rem;

    li input {
      text-transform: uppercase;
      background-color: var(--blue4);
      color: var(--white);
      border: 2px solid var(--white);
      border-radius: var(--font-size-h4);
      font-family: var(--font-family-OpenSans);
      font-size: var(--font-size-base);
      font-weight: var(--font-weight-extrabold);
      padding: 0.5rem 2rem;
      margin-left: 1rem;
      transition: all 0.3s cubic-bezier(0.57, 0.12, 0.16, 0.92);

      &:hover,
      &:active {
        background-color: var(--blue3);
        border: 2px solid var(--blue3);
      }
    }
    .submit input {
      background-color: var(--red);
      border: 2px solid var(--red);
      &:hover,
      &:active {
        background-color: var(--yellow);
        border: 2px solid var(--yellow);
        color: var(--blue3);
      }
    }
  }

  @media (min-width: 500px) {
    h2 {
      padding-bottom: 1rem;
    }
    form div {
      display: grid;
      align-items: baseline;
      grid-template-columns: repeat(4, 1fr);
      padding-top: 1rem;
      label {
        padding: 0;
      }
      input,
      textarea {
        grid-column: 2 / 5;
      }
    }
  }

  @media (min-width: 1000px) {
    h2 {
      padding-bottom: 1rem;
    }
    form div {
      display: grid;
      align-items: baseline;
      grid-template-columns: repeat(5, 1fr);
      padding-top: 1rem;
      label {
        padding: 0;
        grid-column: 2 / 3;
      }
      input,
      textarea {
        grid-column: 3 / 5;
      }
    }
    ul {
      justify-content: space-around;
    }
  }
`;

export default StyledContactForm;
